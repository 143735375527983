import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackToWork from "../components/backtowork"

const InternalPage = () => (
  <Layout>
    <SEO title="Art Van Cart" />
    <main className="internal">
      <BackToWork />
      <div className="content">
        <div className="full">
          <h1>Case Study: Art Van Cart</h1>
          <p>Frequent drop-off rates within the Cart prompted an investigation as to why this could be occurring, and the result of this investigation led a full redesign. Throughout the redesign, the product team kept these key things in mind:</p>
          <ul>
            <li>What are some of the pain-points customers are running into while in the Cart (feedback taken from Voice of the Customer)?</li>
            <li>How can we solution for these pain-points? What are we hypothesising will happen if we design solutions for these issues?</li>
            <li>Scrap what we know about Cart as it exists today, and design for the ideal customer journey.</li>
          </ul>
          <h2>Issue #1: Customers are unable to see shipping costs and Delivery Options at the cart level.</h2>
          <p>As it exists today, delivery options are tied to product tiles from within the cart. Meaning customers are able to see what type of delivery each product qualifies for, however they’re unable to make any type of delivery choice from within the cart.</p>
          <p>To complicate things further, products that are delivered by Art Van are placed into one ship group; products that are shipped for free from vendors are placed into a separate ship group. This is not accessible to the customer at the cart level, which leads to confusion and frustration.</p>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649349/hireallisonokeefe/art-van-cart/cart_0_dly3f2.png" alt="" />
            <figcaption>Above: customers asked the question “What do you like, and what could we do to make it better?” // anonymous feedback via Qualtrics survey.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649353/hireallisonokeefe/art-van-cart/cart_01_gh1wrd.png" alt="" />
            <figcaption>Above: close-up of the Art Van cart, April 2019.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649755/hireallisonokeefe/art-van-cart/cart_02_acew7c.png" alt="" />
            <figcaption>Above: close-up of proposed solution, detailing shipments from within the cart.</figcaption>
          </figure>
        </div>
        <div className="full">
          <p>Solution: Allowing customers to make a choice in their delivery options at a cart level will clear up confusion on how to make this choice, resulting in less phone calls to the Help Desk. Showing ship groups at a cart level will also inform the customer of separate deliveries earlier in their checkout journey.</p>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649349/hireallisonokeefe/art-van-cart/cart_03_lpvd5p.png" alt="" />
            <figcaption>Above: detail of proposed design to solve for delivery methods at a cart level.</figcaption>
          </figure>
          <p>To further educate the customer, inclusion of clear descriptions for each type of delivery will help determine what kind of delivery they choose.</p>
          <figure>
            <div className="video-wrapper">
              <iframe title="Prototype of delivery description drawer" src="https://player.cloudinary.com/embed/?cloud_name=innocent&public_id=hireallisonokeefe%2Fart-van-cart%2Fcart-video_wx6ptt&fluid=true&controls=true&source_types%5B0%5D=mp4" allow="fullscreen; encrypted-media; picture-in-picture" allowFullScreen frameBorder="0" />
            </div>
            <figcaption>Above: detailed descriptions of delivery types within a drawer modal, accessible from within the Cart.</figcaption>
          </figure>
          <h2>Issue #2: No Save For Later functionality within the Cart.</h2>
          <p>Based on HotJar heatmap data, Voice of the Customer reports, and anonymous feedback received from Qualtrics surveys, it became clear that customers were using their Carts as a space to compare products, and to come back later when they were ready to purchase. </p>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649350/hireallisonokeefe/art-van-cart/cart_04_wyg5wj.png" alt="" />
            <figcaption>Above: bar graph comparing customer responses to the question “Did you purchase a product during this visit?” // 84 responses of anonymous feedback via Qualtrics survey //0% Yes, 74% No, 26% Not Yet.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649361/hireallisonokeefe/art-van-cart/cart_05_antzxh.png" alt="" />
            <figcaption>Above: customer responses to the question “Do you have any specific feedback about the shopping cart?” // anonymous feedback via Qualtrics survey.</figcaption>
          </figure>
        </div>
        <div className="half">
          <p>What the Product Detail Pages lacked was a method for customers to compare product. After discussing options with Salesforce business analysts, the level of effort to develop product compare functionality was significant enough that it made more sense to investigate use of Save For Later functionality within the cart instead.</p>
          <p>Due to customer response, this seemed to be how customers were using the cart already — even without the current availability of Save For Later within the cart.</p>
        </div>
        <div className="full">
          <p>Solution: Investigate competitor use of Save For Later functionality within the checkout journey, and design for this experience on ArtVan.com. This will allow customers to quickly save product they’re not ready to purchase for later.</p>
        </div>
        <div className="half">
          <figure>
            <div className="slide-wrapper art-van-cart-1">
              <iframe title="Google Slides presentation showcasing an exploratory report on save for later functionality" src="https://docs.google.com/presentation/d/e/2PACX-1vQ6FBpb40WUeXyx3S9HnhyqNX_J5aI7tImUAi4PVvvUyTzDaJEOdjCwX-lvSGn4Ag/embed?start=false&loop=false&delayms=60000" frameBorder="0" width="743" height="448" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />
            </div>
            <figcaption>Above: exploratory ticket report on Save For Later.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649357/hireallisonokeefe/art-van-cart/cart_06_snxdvm.png" alt="" />
            <figcaption>Above: detail of proposed solution of Save For Later functionality within the cart.</figcaption>
          </figure>
        </div>
        <div className="full">
          <h2>Issue #3: Cart Summary does not give a breakdown of price by line item.</h2>
          <p>There were some initial Quick Wins to the Cart Summary that were added to improve customer experience, the most specific centered around Warranty and how it was displayed to the customer.</p>
          <p>Customers who added a Warranty to their purchase did not see this listed as a line item within the Cart Summary. Once applied, price of the Warranty was added to their Subtotal increasing the overall price number within the customers order. Understandably, this caused some confusion.</p>
          <p>Quick Win Solution: add an asterisk underneath Subtotal, detailing that the subtotal number includes the addition of that Warranty selection. Due to bandwidth and LOE from Salesforce Developers, this was the quickest solution for the time.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649357/hireallisonokeefe/art-van-cart/cart_07_elacdj.png" alt="" />
            <figcaption>Above: detail of Quick Win solution to warranty listed within the Cart Summary.</figcaption>
          </figure>
        </div>
        <div className="half">
          <p>Even with this addition, it was not the correct solution. We had confirmation of this confusion from Voice of the Customer reports, and anonymous feedback recieved from Qualtrics surveys that customers still wanted to see fees as line items within their Cart Summary.</p>
          <p>Solution: include line items for Warranty, Tax, Discounts via Coupon Codes or product discounts. Inclusion of total savings gives customer peace-of-mind that they’re getting a decent deal.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649357/hireallisonokeefe/art-van-cart/cart_08_kbx6m0.jpg" alt="" />
            <figcaption>Above: close-up of Cart Summary concept.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649357/hireallisonokeefe/art-van-cart/cart_09_elvopm.png" alt="" />
              <figcaption>Above: detail of Cart Summary solution, with a discount applied.</figcaption>
          </figure>
        </div>
        <div className="full">
          <h2>Issue #4: Product tiles are cluttered with information.</h2>
          <p>Much of the information being shown on a product within the cart is redundant and not necessary at this stage of the checkout journey. Cleaning up what we are showing the customer will help reduce confusion and improve the overall look and feel.</p>
          <p>Redundant information includes:</p>
          <ul>
            <li>In Stock messaging and unclear In Stock messaging (Out of Stock remains)</li>
            <li>Color (if it is already listed within the product name)</li>
            <li>Repetitive delivery options across every product</li>
            <li>Promotional messaging</li>
          </ul>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584650045/hireallisonokeefe/art-van-cart/cart_11_g6llof.png" alt="" />
            <figcaption>Above: detail of product tiles within the Cart before removing unnecessary information.</figcaption>
          </figure>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649360/hireallisonokeefe/art-van-cart/cart_10_tfrsea.png" alt="" />
            <figcaption>Above: detail of product tiles within the Cart after removing unnecessary information.</figcaption>
          </figure>
          <p>Cleaning up product tiles and utilizing shipment groups to separate the various deliveries makes it easier for the customer to interpret how they will be receiving their delivery and the messages across each product.</p>
          <h2>Expected Results</h2>
          <p>The improvements made to the Shopping Cart will reduce the amount of customer confusion and increase conversion rates.</p>
          <div className="slide-wrapper">
            <iframe title="Google Slides showing cart competitive analysis" src="https://docs.google.com/presentation/d/e/2PACX-1vQ65A8plG9uFyB5V-0YaWDcVh-DKIBLIS70iurXCgOhI3idCQXAtCAdfs6i97rDVOjHkrM3o4MT_tlY/embed?start=false&loop=false&delayms=60000" frameBorder="0" width="743" height="448" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />
          </div>
          <p><span className="text--bold">Programs used:</span> Sketch App</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default InternalPage
